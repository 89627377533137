<template>
    <div>
        <LoaderIcon v-if="requestInFlight"/>
        <TableBaseFilterable
            v-else
            class="clear-footer delete-me"
            cardElevation="3"
            :filter.sync="poSearchFilter"
            :selected.sync="selectedPurchaseOrderList"
            :headers="headers"
            :items="purchaseOrderList"
            :itemClass="setRowHighlight"
            :customSort="ArrayUtil.sort"
            :sortBy="tableSortBy"
            :sortDesc="tableSortDesc"
            showSelect
            hideAdd
            filterLabel="Filter Purchase Order Lines..."
            @click:row="( e, { item } ) => displayPoDetail( item )"
            @update:options="( payload ) => updateTableOptionsHandler( payload )"
        >
            <template #[`item.status`]="{ item }">
                <v-btn
                    v-if="isLinkStatus( item )"
                    :loading="getLinkInFlight"
                    color="primary"
                    role="link"
                    text
                    @click.stop.prevent="getLink( item )"
                >
                    {{ item.status }}
                </v-btn>
                <div v-else>{{ item.status }}</div>
            </template>
            <template #[`item.expectedReceiveDate`]="{ item }">
                {{ item.expectedReceiveDate ? FormatUtil.yearMonthDayToSimpleString( item.expectedReceiveDate ) : 'None' }}
            </template>
            <template #[`item.createDate`]="{ item }">
                {{ item.createDate ? FormatUtil.dateTimeToSimpleString( item.createDate ) : 'None' }}
            </template>
        </TableBaseFilterable>

        <v-dialog
            v-model="pubNetConfirmation"
            persistent
            max-width="290"
        >
            <v-card>
                <v-card-title class="text-h5">
                    Send to PubNet
                </v-card-title>
                <v-card-text>
                    <p>Are you sure you want to send {{selectedPurchaseOrderList.length}} Purchase Orders to PubNet?</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        outlined
                        @click="pubNetConfirmation = false"
                    >
                        No
                    </v-btn>
                    <v-btn
                        color="primary darken-1"
                        elevation="0"
                        @click="() => {
                            sendToPubNet(selectedPurchaseOrderList)
                            pubNetConfirmation = false
                        }"
                    >
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Page Footer Bar -->
        <ThePageFooterBar>
            <!-- Send to PubNet -->
            <v-btn
                v-if="canSendToPubNet"
                :disabled="!hasSelectedPurchaseOrders"
                color="primary"
                class="mr-2"
                elevation="0"
                @click="pubNetConfirmation = true"
            >
                <v-icon left>mdi-send</v-icon>
                Send to PubNet
            </v-btn>

            <!-- Print PO -->
            <v-btn
                :disabled="!hasSelectedPurchaseOrders"
                color="primary"
                elevation="0"
                @click="printSelectedPurchaseOrders()"
            >
                <v-icon left>mdi-printer</v-icon>
                Print
            </v-btn>
        </ThePageFooterBar>
    </div>
</template>

<script>
// Vuex
import { mapGetters, mapState, mapActions } from 'vuex';

// Util
import ArrayUtil from '@/utils/ArrayUtil';
import FormatUtil from '@/utils/FormatUtil';

// Components
import ThePageFooterBar from '@/components/ThePageFooterBar';
import LoaderIcon from '@/components/base/loaders/LoaderIcon';
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';

import InvoiceRoute from '@/router/routes/InvoiceReconciliation';

/**
 * @emits 'showPoDetail' => { import('@/typedefs/purchaseOrder').purchaseOrder }
 */
export default {
    name: 'SummaryView',
    components: {
        ThePageFooterBar,
        LoaderIcon,
        TableBaseFilterable,
    },
    data() {
        return {
            headers: [
                {
                    text: 'PO Number',
                    value: 'number',
                },
                {
                    text: 'Location',
                    value: 'locationFriendly',
                },
                {
                    text: 'Supplier',
                    value: 'supplierFriendly',
                },
                {
                    text: 'Notes',
                    value: 'notesFriendly',
                    sortable: false,
                    cellClass: 'notes-field',
                },
                {
                    text: 'Units Ord',
                    align: 'end',
                    value: 'unitsOrdered',
                },
                {
                    text: 'EANs',
                    align: 'end',
                    value: 'isbnsOrdered',
                },
                {
                    text: 'Create Date',
                    value: 'createDate',
                },
                {
                    text: 'Expected Receive Date',
                    value: 'expectedReceiveDate',
                },
                {
                    text: 'Status',
                    value: 'status',
                    align: 'center',
                },
            ],
            selectedPurchaseOrderList: [],
            getLinkInFlight: false,
            FormatUtil,
            ArrayUtil,
            pubNetConfirmation: false,
        };
    },
    computed: {
        ...mapGetters( {
            purchaseOrderHasItems: 'PurchaseOrder/hasItems',
            canSendToPubNet: 'User/canSendToPubNet',
        } ),
        ...mapState( {
            currentPurchaseOrder: ( state ) => state.PurchaseOrder.Detail.currentPurchaseOrder,
            purchaseOrderList: ( state ) => state.PurchaseOrder.list,
            requestInFlight: ( state ) => state.PurchaseOrder.inFlight,
            tableSortBy: ( state ) => state.PurchaseOrder.table.sortBy,
            tableSortDesc: ( state ) => state.PurchaseOrder.table.sortDesc,
            tableFilter: ( state ) => state.PurchaseOrder.tableFilter,
            invoiceList: ( state ) => state.Invoice.list,
            locationList: ( state ) => state.GlobalResources.locationList,
            supplierList: ( state ) => state.GlobalResources.primarySupplierList,
        } ),

        hasSelectedPurchaseOrders() {
            return this.selectedPurchaseOrderList.length > 0;
        },

        /** @returns { String } */
        poSearchFilter: {
            get() {
                return this.tableFilter;
            },
            set( val ) {
                this.$store.dispatch( 'PurchaseOrder/setTableFilter', { tableFilter: val } );
            },
        },
    },
    watch: {
        requestInFlight() {
            // Clear selectedPurchaseOrderList when a new request is made
            if ( this.requestInFlight ) {
                this.selectedPurchaseOrderList = [];
            }
        },
    },
    methods: {
        ...mapActions( {
            reset: 'Invoice/reset',
            setFilterPurchaseOrderNumber: 'Invoice/setFilterPurchaseOrderNumber',
            setFilterLocation: 'Invoice/setFilterLocation',
            setFilterPrimarySupplier: 'Invoice/setFilterPrimarySupplier',
            getByFilters: 'Invoice/getByFilters',
            sendToPubNet: 'PurchaseOrder/sendToPubNet',
        } ),
        /**
         * Emit an event to bring up the Purchase Order Detail page
         * @emits 'showPoDetail' => { import('@/typedefs/purchaseOrder').purchaseOrder }
         */
        displayPoDetail( { number } ) {
            // Clear the selected purchase orders when going to a detail page
            this.selectedPurchaseOrderList = [];
            // The purchase order parameter is a formatted purchase order from formattedPurchaseOrderList,
            // so we need to get all of that purchase order's information
            const purchaseOrderIndex = this.purchaseOrderList.findIndex( ( order ) => order.number === number );
            this.$emit( 'showPoDetail', this.purchaseOrderList[ purchaseOrderIndex ] );
        },
        /**
         * Print purchase orders in the selectedPurchaseOrderList
         */
        printSelectedPurchaseOrders() {
            this.$store.dispatch( 'PurchaseOrder/print', {
                purchaseOrderList: this.selectedPurchaseOrderList,
            } );
        },
        /** @returns { String } */
        setRowHighlight( item ) {
            return item.number === this.currentPurchaseOrder.number
                ? 'selected-border'
                : '';
        },
        /** @returns { Boolean } */
        isLinkStatus( item ) {
            return [ 'submitted', 'received', 'reconcile' ].includes( item.status.toLowerCase() );
        },
        async getLink( item ) {
            this.getLinkInFlight = true;
            await this.reset();
            await this.setFilterPurchaseOrderNumber( {
                purchaseOrderNumber: item.number,
            } );
            await this.getByFilters();
            this.getLinkInFlight = false;

            if ( this.invoiceList.length === 0 ) {
                await this.$router.push( {
                    path: `${ InvoiceRoute.path }/create`,
                    query: {
                        locationId: item.location?.id,
                        supplierId: item.supplier?.id,
                    },
                } );
            } else {
                const foundLocation = this.locationList.find( ( location ) => location.id === item.location?.id );
                if ( foundLocation ) {
                    await this.$store.dispatch( 'Invoice/setFilterLocation', {
                        location: foundLocation,
                    } );
                }
                const foundSupplier = this.supplierList.find( ( supplier ) => supplier.id === item.supplier?.id );
                if ( foundSupplier ) {
                    await this.$store.dispatch( 'Invoice/setFilterPrimarySupplier', {
                        primarySupplier: foundSupplier,
                    } );
                }
                await this.$router.push( {
                    path: InvoiceRoute.path,
                    query: {
                        locationId: item.location?.id,
                        supplierId: item.supplier?.id,
                    },
                } );
            }
        },
        updateTableOptionsHandler( { sortBy, sortDesc } ) {
            this.$store.dispatch( 'PurchaseOrder/setTableSortOptionsAndSortList', {
                sortBy,
                sortDesc,
            } );
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/customDatatable.scss";

::v-deep .selected-border td:first-child {
    border-left: 4px solid var(--v-primary-base);
}
::v-deep .notes-field {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 10rem;
}
.clear-footer {
    margin-bottom: 88px;
}
</style>
